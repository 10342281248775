import { z } from 'zod';

export enum AssessmentFormQuestionItemType {
  Question = 1,
  Filter = 2,
}

export enum AssessmentFormQuestionType {
  MultipleChoice = 1,
  SingleChoice = 2,
  FreeText = 3,
  Range = 4,
  Nps = 5,
  Dropdown = 6,
  Date = 7,
  Rating = 8,
}
export enum OldAssessmentFormQuestionType {
  MULTIPLE_CHOICE = AssessmentFormQuestionType.MultipleChoice,
  SINGLE_CHOICE = AssessmentFormQuestionType.SingleChoice,
  FREE_TEXT = AssessmentFormQuestionType.FreeText,
  RANGE = AssessmentFormQuestionType.Range,
  DROPDOWN = AssessmentFormQuestionType.Dropdown,
  DATE = AssessmentFormQuestionType.Date,
  RATING = AssessmentFormQuestionType.Rating,
}

export enum AssessmentFormInstanceAttemptStatus {
  InProgress = 1,
  Scoring = 2,
  Ended = 3,
}

export enum AssessmentFormInstanceStatus {
  NotStarted = 1,
  InProgress = 2,
  Scoring = 3,
  Passed = 4,
  Failed = 5,
  Canceled = 6,
}

export enum AssessmentFormValidationType {
  LastScore = 1,
  BestScore = 2,
}

export const AssessmentFormSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  thumbnail: z.string(),
  minScore: z.number(),
  duration: z.number(),
  maxAttemptsCount: z.number(),
  validationType: z.number(),
  showResults: z.boolean(),
  randomizeQuestionOrder: z.boolean(),
  allowRetakeAfterPassed: z.boolean().default(false),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  userId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AssessmentForm = z.infer<typeof AssessmentFormSchema>;

export const AssessmentQuestionOptionsSchema = z
  .array(z.object({ title: z.string(), correct: z.boolean() }))
  .default([]);
export type AssessmentQuestionOptions = z.infer<
  typeof AssessmentQuestionOptionsSchema
>;

export const AssessmentQuestionSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  type: z.nativeEnum(AssessmentFormQuestionType),
  options: AssessmentQuestionOptionsSchema,
  tagIds: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AssessmentQuestion = z.infer<typeof AssessmentQuestionSchema>;

export const AssessmentFormInstanceSchema = z.object({
  id: z.string(),
  assessmentFormId: z.string(),
  learnerId: z.string(),
  attemptsCount: z.number(),
  status: z.nativeEnum(AssessmentFormInstanceStatus),
  scoredAssessmentFormInstanceAttemptId: z.string().nullable(),
  score: z.number().nullable(),
  learningAssignmentInstanceId: z.string().nullable(),
  maxAttemptsCount: z.number(),
  maxAttemptsCountReached: z.boolean(),
  learnerHistoryId: z.string().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AssessmentFormInstance = z.infer<
  typeof AssessmentFormInstanceSchema
>;

export const AssessmentFormInstanceAttemptSchema = z.object({
  id: z.string(),
  assessmentFormInstanceId: z.string(),
  startedOn: z.date().nullable(),
  finishedOn: z.date().nullable(),
  answers: z.array(z.string()),
  score: z.number().nullable(),
  status: z.number(),
  timeSpent: z.number().nullable(),
  assessmentQuestions: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AssessmentFormInstanceAttempt = z.infer<
  typeof AssessmentFormInstanceAttemptSchema
>;

export const AssessmentFormInstanceAttemptQuestionSchema = z.object({
  id: z.string(),
  assessmentFormInstanceAttemptId: z.string(),
  assessmentFormInstanceId: z.string(),
  assessmentQuestionId: z.string(),
  assessmentFormItemId: z.string(),
  isCorrect: z.boolean().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});
export type AssessmentFormInstanceAttemptQuestion = z.infer<
  typeof AssessmentFormInstanceAttemptQuestionSchema
>;
