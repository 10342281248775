import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  Subscription,
  SubscriptionCostType,
} from '@nl-lms/feature/subscriptions/sdk';
import {
  Box,
  Button,
  FormField,
  Input,
  Separator,
  SideModal,
  SingleSelect,
  Typography,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { _ } from '@nl-lms/vendor';

import { useSubmitUpsertEntityFromSideModal } from '../../../_common/hooks/useSubmitUpsertEntityFromSideModal';
import { subscriptionsApi } from '../../../_common/services/api';
import { AdminTagSelect } from '../AdminTag/AdminTagSelect';
import { AdminVendorSelect } from '../AdminVendor/AdminVendorSelect';

type Props = {
  subscription?: Partial<
    Subscription & {
      vendor: { name: string; id: string };
      tags: { title: string; scope: string; id: string };
    }
  >;
};

// TODO: Add validation that prevents partial cost fields
const Schema = z.object({
  name: z.string().min(1),
  url: z.string().min(1),
  tagIds: z.array(z.string()).optional(),
  vendorId: z.string().min(1),
  costType: z.nativeEnum(SubscriptionCostType).nullable(),
  baseCost: z.number().nullable(),
  costCurrency: z.number().nullable(),
});

export const AdminSubscriptionEditFormSideModal = ({
  subscription = {},
}: Props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...subscription,
      baseCost: subscription.baseCost || null,
      costType: subscription.costType || SubscriptionCostType.PerIndividual,
      costCurrency: subscription.costCurrency || C.CURRENCY,
    },
  });
  const { onSubmit, isLoading, error } = useSubmitUpsertEntityFromSideModal({
    updateHookName: 'useUpdateSubscriptionMutation',
    createHookName: 'useCreateSubscriptionMutation',
    entityId: subscription.id,
    parseEntity: (payload) => {
      const baseCost =
        payload.baseCost && payload.costCurrency && payload.costType
          ? payload.baseCost
          : null;
      const costCurrency =
        payload.baseCost && payload.costCurrency && payload.costType
          ? payload.costCurrency
          : null;
      const costType =
        payload.baseCost && payload.costCurrency && payload.costType
          ? payload.costType
          : null;
      return {
        ..._.omit(payload, ['vendor', 'tags']),
        subscriptionId: subscription.id,
        baseCost,
        costCurrency,
        costType,
      };
    },
    api: subscriptionsApi,
  });

  const costCurriencesOptions = Object.keys(C.CURRENCIES).map((key) => ({
    value: parseInt(key),
    label: C.CURRENCIES[key],
  }));

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>
          {subscription.id ? 'Edit Subscription' : 'Create New Subscription'}
        </SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Name"
              helpText="The name that we will use throughout the entire app in various forms, reports and views"
              helpTextOffset={60}
              required
              errorMessage={errors?.name?.message}
            >
              <Input
                required
                {...register('name')}
                hasError={!!errors?.name?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="URL"
              helpText="The URL for the subscription page"
              helpTextOffset={30}
              errorMessage={errors?.url?.message}
            >
              <Input
                {...register('url')}
                required
                hasError={!!errors?.url?.message}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Vendor" required>
              <Controller
                name="vendorId"
                control={control}
                render={({ field }) => (
                  <AdminVendorSelect
                    {...field}
                    isClearable
                    hasError={!!errors?.vendorId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Tags">
              <Controller
                name="tagIds"
                control={control}
                render={({ field }) => (
                  <AdminTagSelect {...field} selectedItems={field.value} />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Separator />
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Reference Cost</Typography.h2>
          <Box margin={{ top: 's' }}>
            <Typography.p type="muted">
              This is not the actual cost of the subscription. It will be used
              as a reference value, later, when you add the learners.
            </Typography.p>
          </Box>
        </Box>
        <Box flex={{ gap: 's', flexDirection: 'row' }}>
          <FormField label="Cost">
            <Input
              type="number"
              {...register('baseCost', { valueAsNumber: true })}
              placeholder="Cost"
              hasError={!!errors?.baseCost?.message}
            />
          </FormField>
          <FormField label="Currency">
            <Controller
              name="costCurrency"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  initialSelectedItem={field.value}
                  hasError={!!errors?.costCurrency?.message}
                  placeholder="Search cost currencies"
                  options={costCurriencesOptions}
                />
              )}
            />
          </FormField>

          <FormField label="Cost Type">
            <Controller
              name="costType"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  initialSelectedItem={field.value}
                  hasError={!!errors?.costType?.message}
                  options={[
                    {
                      label: 'Per Learner',
                      value: SubscriptionCostType.PerIndividual,
                    },
                    {
                      label: 'Per Group',
                      value: SubscriptionCostType.PerGroup,
                    },
                  ]}
                />
              )}
            />
          </FormField>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <SideModal.Error>
          {getTranslatedMessageFromError(error)}
        </SideModal.Error>
        <Button
          label={subscription.id ? 'Save' : 'Create'}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
