import React, { useCallback, useMemo } from 'react';

import {
  AsyncSingleSelect,
  SelectSkeleton,
  SingleSelect,
  SingleSelectProps,
  getSelectOptions,
} from '@nl-lms/ui/components';

import { adminApi, useApi } from '../../../_common/services/api';
import { fetchAndMapVendors } from '../../_common/utils/fetchEntitiesForSelectMethods';

const { useListVendorsForSelectQuery } = adminApi;

const buildLabel = (item) => {
  return item.name;
};

const parseOptions = (res) => getSelectOptions(res, buildLabel);

const parseSelectedItem = (selectedItem, options) => {
  if (!selectedItem) return null;
  if (typeof selectedItem === 'string') {
    return options.find((o) => selectedItem === o.value);
  } else return selectedItem;
};

export const AdminVendorSelect = (
  props: Omit<SingleSelectProps, 'options'> & { value?: string | null },
) => {
  const {
    onChange,
    name,
    placeholder = 'Search by vendor name',
    disabled = false,
    isClearable = false,
    hasError = false,
    initialSelectedItem = null,
    selectedItem = null,
    value = null,
  } = props;

  // @ts-ignore
  const { data, isFetching } = useListVendorsForSelectQuery(null);
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data);
  }, [data]);

  const selectedValue = parseSelectedItem(value, options) || null;
  const initialSelectedValue =
    parseSelectedItem(initialSelectedItem, options) || null;

  const handleOnChangeInputValue = (value) => {
    if (!value) {
      onChange(null, '');
    }
  };

  if (isFetching) {
    return (
      <SelectSkeleton
        hasError={hasError}
        placeholder={'Loading vendors...'}
        disabled={disabled}
      />
    );
  }

  return (
    <AsyncSingleSelect
      onChange={onChange}
      onChangeInputValue={handleOnChangeInputValue}
      name={name}
      placeholder={placeholder}
      loadOptions={fetchAndMapVendors}
      isClearable={isClearable}
      disabled={disabled}
      hasError={hasError}
      // @ts-ignore
      initialSelectedItem={initialSelectedValue}
      selectedItem={selectedValue}
    />
  );
};
