import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { z } from 'zod';

import {
  Subscription,
  SubscriptionCostType,
} from '@nl-lms/feature/subscriptions/sdk';
import {
  Box,
  Button,
  DateTime,
  FormField,
  Input,
  Separator,
  SideModal,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

import { useAction } from '../../../_common/hooks/useAction';
import { subscriptionsApi } from '../../../_common/services/api';
import { AdminLearnerMultiSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminSubscriptionSelect } from './AdminSubscriptionSelect';

const Schema = z.object({
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  subscriptionId: z.string().min(1),
  learnerIds: z.array(z.string()),
  cost: z.number().min(1).nullable(),
  costCurrency: z.number().nullable(),
  costType: z.nativeEnum(SubscriptionCostType).nullable(),
});

type SchemaType = z.infer<typeof Schema>;

const { useCreateSubscriptionInstancesMutation } = subscriptionsApi;

export const AdminSubscriptionInstanceCreateFormSideModal = ({
  subscriptionId,
}: {
  subscriptionId?: string;
}) => {
  const { hide } = useModal();

  const methods = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      subscriptionId,
      startDate: null,
      endDate: null,
      learnerIds: [],
      cost: null as number | null,
      costCurrency: null as number | null,
      costType: null as SubscriptionCostType | null,
    },
  });
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const [createSubscriptionInstances, { isLoading, error }] =
    useCreateSubscriptionInstancesMutation();

  const onSubmit = useCallback(async (data: SchemaType) => {
    let cost = data.cost && data.costCurrency ? data.cost : null;
    const costCurrency =
      data.cost && data.costCurrency ? data.costCurrency : null;
    if (cost && data.costType === SubscriptionCostType.PerGroup) {
      cost = cost / data.learnerIds.length;
    }
    const res = await createSubscriptionInstances(
      data.learnerIds.map((learnerId) => ({
        learnerId,
        startDate: data.startDate,
        endDate: data.endDate,
        subscriptionId: data.subscriptionId,
        cost,
        costCurrency,
      })),
    );

    hide();

    return res;
  }, []);

  const onChangeSubscription = useCallback(
    (value) => {
      const subscription = value?.entity as Subscription | null;
      if (!subscription) {
        // @ts-expect-error
        setValue('subscriptionId', null);
        setValue('costCurrency', null);
        setValue('cost', null);
        setValue('costType', null);
        return;
      }
      setValue('subscriptionId', subscription.id);
      setValue('costCurrency', subscription.costCurrency);
      setValue('cost', subscription.baseCost);
      setValue('costType', subscription.costType);
    },
    [setValue],
  );

  const onSubmitAction = useAction(onSubmit, {
    successMessage: 'Subscriptions successfully added',
    alertMessage: 'Subscriptions could not be added',
  });

  const costCurriencesOptions = Object.keys(C.CURRENCIES).map((key) => ({
    value: parseInt(key),
    label: C.CURRENCIES[key],
  }));

  return (
    <FormProvider {...methods}>
      <SideModal.Content>
        <SideModal.Header>
          <SideModal.Title>Create New Subscription</SideModal.Title>
        </SideModal.Header>
        <SideModal.Body>
          <Box margin={{ bottom: 'm' }}>
            <Typography.h2>Details</Typography.h2>
            <Box margin={{ top: 's' }}>
              <Typography.p type="muted">
                Set the main information for the subscriptions
              </Typography.p>
            </Box>
          </Box>
          <Box>
            <Box>
              <FormField
                label="Learners"
                helpText="The list of learners for which the subscription will be added"
                helpTextOffset={60}
                required
                errorMessage={errors?.learnerIds?.message}
              >
                <Controller
                  name="learnerIds"
                  control={control}
                  render={({ field }) => (
                    <AdminLearnerMultiSelect
                      {...field}
                      returnEntireItemOnChange={false}
                    />
                  )}
                />
              </FormField>
            </Box>
          </Box>
          {!subscriptionId ? (
            <Box>
              <Box>
                <FormField
                  required
                  label="Subscription"
                  helpText="The subscription you want to track for the elarner"
                  helpTextOffset={30}
                  errorMessage={errors?.subscriptionId?.message}
                >
                  <Controller
                    control={control}
                    name="subscriptionId"
                    render={({ field }) => (
                      <AdminSubscriptionSelect
                        {...field}
                        returnEntireItemOnChange
                        onChange={onChangeSubscription}
                        isClearable
                        hasError={!!errors?.subscriptionId?.message}
                      />
                    )}
                  />
                </FormField>
              </Box>
            </Box>
          ) : null}
          <Box>
            <Box>
              <FormField label="Start Date">
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DateTime
                      date={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </FormField>
            </Box>
            <Box>
              <FormField label="End Date">
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DateTime
                      date={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </FormField>
            </Box>
          </Box>
          <Separator marginTop={10} />
          <Box margin={{ bottom: 'm' }}>
            <Typography.h2>Cost</Typography.h2>
            <Box margin={{ top: 's' }}>
              <Typography.p type="muted">
                Track the cost of the new subscriptions
              </Typography.p>
            </Box>
          </Box>
          <Box flex={{ gap: 's', flexDirection: 'row' }}>
            <FormField label="Cost">
              <Input
                type="number"
                {...register('cost', { valueAsNumber: true })}
                placeholder="Add the subscription value"
                hasError={!!errors?.cost?.message}
              />
            </FormField>
            <FormField label="Currency">
              <Controller
                name="costCurrency"
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    // @ts-expect-error
                    selectedItem={field.value}
                    // @ts-expect-error
                    initialSelectedItem={field.value}
                    hasError={!!errors?.costCurrency?.message}
                    placeholder="Search currencies"
                    options={costCurriencesOptions}
                  />
                )}
              />
            </FormField>
            <FormField label="Cost Type">
              <Controller
                name="costType"
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    // @ts-expect-error
                    selectedItem={field.value}
                    placeholder="Select the cost type"
                    // @ts-expect-error
                    initialSelectedItem={field.value}
                    hasError={!!errors?.costType?.message}
                    options={[
                      {
                        label: 'Per Learner',
                        value: SubscriptionCostType.PerIndividual,
                      },
                      {
                        label: 'Per Group',
                        value: SubscriptionCostType.PerGroup,
                      },
                    ]}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box margin={{ top: 's' }}>
            <SubscriptionCostInfo />
          </Box>
        </SideModal.Body>

        <SideModal.Actions>
          <Button
            label={'Create'}
            // @ts-expect-error
            onClick={handleSubmit(onSubmitAction)}
            isLoading={isLoading}
          />
        </SideModal.Actions>
      </SideModal.Content>
    </FormProvider>
  );
};

function SubscriptionCostInfo() {
  const { watch } = useFormContext();

  const costCurrency = watch('costCurrency');
  const costType = watch('costType');
  const cost = watch('cost');
  const learnerIds = watch('learnerIds');

  if (!costCurrency || !costType || !cost) return null;

  const currencySymbol = C.CURRENCY_SYMBOLS[C.CURRENCIES[costCurrency]];

  if (!learnerIds && costType === SubscriptionCostType.PerIndividual) {
    return (
      <Box flex={{ gap: 'm', flexDirection: 'row' }}>
        <Box flex={{ gap: 's', flexDirection: 'column' }}>
          <Typography.h3>Total Cost</Typography.h3>
          <Typography.h3>-</Typography.h3>
        </Box>

        <Box>
          <Typography.h3>Per Learner:</Typography.h3>
          <Typography.h3>{cost}</Typography.h3>
        </Box>
      </Box>
    );
  }

  const totalCost =
    costType === SubscriptionCostType.PerIndividual
      ? cost * learnerIds.length
      : cost;

  const costPerLearner =
    costType === SubscriptionCostType.PerIndividual
      ? cost
      : (cost / learnerIds.length).toFixed(2);

  return (
    <Box
      flex={{ gap: 'xl', flexDirection: 'row', justifyContent: 'space-evenly' }}
    >
      <Box flex={{ gap: 'm', flexDirection: 'column' }}>
        <Typography.h2>Total Cost</Typography.h2>
        <Typography.h1>
          {currencySymbol} {totalCost}
        </Typography.h1>
      </Box>

      <Box flex={{ gap: 'm', flexDirection: 'column' }}>
        <Typography.h2>Cost per Learner</Typography.h2>
        <Typography.h1>
          {currencySymbol} {costPerLearner}
        </Typography.h1>
      </Box>
    </Box>
  );
}
