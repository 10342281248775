import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  Button,
  Icon,
  RotateIcon,
  Separator,
  SideModal,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { decodeHTML } from '@nl-lms/ui/utils';
import { useQueryErrorHandler } from '@nl-lms/web/_common/hooks';
import { useAction } from '@nl-lms/web/_common/hooks/useAction';
import { LearnerLibraryListCard } from '@nl-lms/web/learnerApp/_common/components/LearnerLibraryListCard/LearnerLibraryListCard';
import { learnerLibraryByCompetenciesFilter } from '@nl-lms/web/learnerApp/modules/LearnerLibraryList/LearnerLibraryFilters';

import { authStore } from '../../../../_common/modules/Auth/auth';
import { SurveyPlayer } from '../../../../_common/modules/SurveyPlayer/SurveyPlayer';
import {
  contentLibraryItemsApi,
  elearningApi,
  learnerApi,
} from '../../../../_common/services/api';
import './LearnerElearningPlayerSuccessModal.scss';

const {
  useGetSurveyInstanceQuery,
  useGetFeedbackSurveyFormQuery,
  useSubmitFeedbackSurveyFormMutation,
} = learnerApi;
const { useGetElearningCourseQuery } = elearningApi;
const { useListContentLibraryItemsQuery } = contentLibraryItemsApi;

const parseResponse = (surveyForm) => ({
  ...surveyForm,
  questions: surveyForm?.questions?.map((q) => ({
    ...q,
    options: q.options.map((o) => ({
      ...o,
      title: decodeHTML(o.title),
    })),
  })),
});

export const LearnerElearningPlayerSuccessModal = ({
  elearningCourseId,
  surveyFormId,
  surveyFormInstanceId,
  showLogo = true,
  showSuccess = true,
  showFeedback = true,
  showLibrary = true,
}) => {
  const { hide } = useModal();

  const { t } = useTranslation('learner');

  const [formTimeSpent, setFormTimeSpent] = useState(0);
  const [repsonseEditingEnabled, setRepsonseEditingEnabled] =
    useState(!surveyFormInstanceId);
  const [responseEditVisible, setResponseEditVisible] =
    useState(!!surveyFormInstanceId);

  const learnerId = authStore.learnerId!;
  const getSurveyFormDataArguments = useMemo(
    () => ({
      learnerId,
      surveyFormId,
      learningUnitId: elearningCourseId,
    }),
    [surveyFormId, elearningCourseId],
  );

  const {
    isLoading: isLoadingGetSurveyForm,
    error: getSurveyDataError,
    data: rawData,
    isSuccess: hasFetchedSurveyFormData,
  } = useGetFeedbackSurveyFormQuery(getSurveyFormDataArguments);
  useQueryErrorHandler({ error: getSurveyDataError });

  const surveyForm = useMemo(() => parseResponse(rawData || {}), [rawData]);

  const {
    data: surveyFormInstance,
    isLoading: isGetLoading,
    error: getError,
  } = useGetSurveyInstanceQuery({
    learnerId,
    id: surveyFormInstanceId,
  });
  useQueryErrorHandler({ error: getError });

  const elearningCourseArguments = useMemo(() => ({ elearningCourseId }), []);
  const { data: elearningCourse, error: courseError } =
    // @ts-ignore
    useGetElearningCourseQuery(elearningCourseArguments);
  useQueryErrorHandler({ error: courseError });

  const [
    submitFeedbackSurveyForm,
    { isLoading: isSubmitLoading, isSuccess: isCompleted, error: submitError },
  ] = useSubmitFeedbackSurveyFormMutation();
  const submitFeedbackSurveyFormAction = useAction(submitFeedbackSurveyForm, {
    successMessage: t('successPage.feedback.success'),
  });

  const competencyFilter = useMemo(
    () =>
      learnerLibraryByCompetenciesFilter(elearningCourse?.competencyIds ?? []),
    [elearningCourse, learnerLibraryByCompetenciesFilter],
  );

  const { data: contentLibraryItemsData, isLoading: isLibraryLoading } =
    useListContentLibraryItemsQuery({
      query: {
        query: transformTsRestQuery({
          filters: competencyFilter,
          pagination: {
            disabled: true,
          },
        }),
      },
    });

  const contentLibraryItems = useMemo(
    () =>
      elearningCourse?.competencyIds?.length
        ? (contentLibraryItemsData?.rows ?? [])
        : [],
    [contentLibraryItemsData, elearningCourse],
  );

  const initialAnswers = useMemo(() => {
    if (!surveyFormInstance) return null;

    // @ts-ignore
    return surveyFormInstance.surveyFormInstanceQuestions.reduce((acc, val) => {
      return {
        ...acc,
        [val.questionId]: val.answer.map((a) => a.replaceAll('&amp;', '&')),
      };
    }, {});
  }, [surveyFormInstance]);

  const onSubmit = useCallback(
    async (id, answers, timeSpent) => {
      setFormTimeSpent(Math.ceil(timeSpent / 1000));
      await submitFeedbackSurveyFormAction({
        learnerId,
        surveyFormId,
        learningUnitId: elearningCourseId,
        answers,
        timeSpent,
      });
      setRepsonseEditingEnabled(false);
    },
    [elearningCourseId, surveyFormId, formTimeSpent],
  );

  const onEditResponse = useCallback(() => {
    setRepsonseEditingEnabled(true);
  }, []);

  return (
    <SideModal.Content>
      <SideModal.Body className="success-modal">
        <Dialog.Close asChild className="success-modal__close-button">
          <button
            title="Close"
            className="radix-side-modal__header-close-button"
          >
            <Icon.CloseIcon />
          </button>
        </Dialog.Close>

        {!!showLogo && (
          <div className="success-modal__section success-modal__section-logo">
            <img
              className="main-layout__logo-image"
              src={C.LOGO_DETAILS.variation.dark}
            />
          </div>
        )}

        {!!showSuccess && (
          <div className="success-modal__section success-modal__section-info">
            <div className="success-modal__section-wrapper">
              <div className="success-modal__content">
                <div className="success-modal__content-text">
                  <Typography.h1 className="success-modal__section-info-title">
                    {t('successPage.header')}
                  </Typography.h1>
                  <Typography.p>{t('successPage.text')}</Typography.p>
                </div>

                <Separator className="success-modal__section-info-separator" />

                <div className="success-modal__content-actions">
                  <Button
                    regular
                    label={t('successPage.replay')}
                    icon={<RotateIcon />}
                    onClick={hide}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {!!surveyFormId && !!showFeedback && (
          <div className="success-modal__section success-modal__section-feedback">
            <div className="success-modal__section-feedback-header">
              <div className="success-modal__section-wrapper">
                <div className="success-modal__content">
                  <Typography.h2>
                    {t('successPage.feedback.header')}
                  </Typography.h2>
                  <Separator margin={4} transparent />
                  <Typography.p>{t('successPage.feedback.text')}</Typography.p>
                </div>
              </div>
            </div>

            <div className="success-modal__section-feedback-form">
              <div className="success-modal__section-wrapper">
                <div className="success-modal__content">
                  {surveyFormId &&
                    surveyForm &&
                    (!surveyFormInstanceId ||
                      (surveyFormInstanceId && initialAnswers)) && (
                      <>
                        <SurveyPlayer
                          submitBtnLabel={t('successPage.feedback.submit')}
                          surveyForm={{
                            ...surveyForm,
                            timeSpent: formTimeSpent,
                          }}
                          isSubmitLoading={isSubmitLoading}
                          onSubmitSurveyAnswers={onSubmit}
                          showHeader={false}
                          initialAnswers={initialAnswers || undefined}
                          disabled={!repsonseEditingEnabled}
                        />

                        {!repsonseEditingEnabled && responseEditVisible && (
                          <div className="success-modal__section-feedback-form-unlock">
                            <Button
                              onClick={onEditResponse}
                              regular
                              label={t('successPage.feedback.edit')}
                            />
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!!contentLibraryItems?.length && !!showLibrary && (
          <div className="success-modal__section success-modal__section-recommendations">
            <div className="success-modal__section-wrapper">
              <Typography.h2>
                {t('successPage.recommendations.header')}
              </Typography.h2>
              <Separator margin={4} transparent />
              <Typography.p>
                {t('successPage.recommendations.text')}
              </Typography.p>
              <Separator marginBottom={10} transparent />
              <div className="success-modal__carousel">
                {contentLibraryItems.map((item, index) => (
                  <LearnerLibraryListCard {...item} key={item.id} />
                ))}
              </div>
            </div>
          </div>
        )}
      </SideModal.Body>
    </SideModal.Content>
  );
};
