import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { SubscriptionInstance } from '@nl-lms/common/feature/types';
import {
  Box,
  Button,
  DateTime,
  FormField,
  Input,
  SideModal,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';

import { useAction } from '../../../_common/hooks/useAction';
import { subscriptionsApi } from '../../../_common/services/api';
import { AdminLearnerSingleSelect } from '../AdminLearner/AdminLearnerSelect';
import { AdminSubscriptionSelect } from './AdminSubscriptionSelect';

type Props = {
  subscriptionInstance: SubscriptionInstance & {
    learner: { firstName: string; lastName: string; id: string };
    subscription: { name: string; id: string };
  };
};
const Schema = z
  .object({
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
    subscriptionId: z.string().min(1),
    learnerId: z.string().min(1),
    cost: z.number().min(1).nullable(),
    costCurrency: z.number().nullable(),
  })
  .refine(
    (data) =>
      (data.cost === null && data.costCurrency === null) ||
      (data.cost !== null && data.costCurrency !== null),
    {
      message: 'Cost and currency must either both be set or not',
      path: ['cost', 'costCurrency'],
    },
  );

type SchemaType = z.infer<typeof Schema>;

const { useUpdateSubscriptionInstanceMutation } = subscriptionsApi;

export const AdminSubscriptionInstanceUpdateFormSideModal = ({
  subscriptionInstance,
}: Props) => {
  const { hide } = useModal();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...subscriptionInstance,
    },
  });

  const [updateSubscriptionInstance, { isLoading }] =
    useUpdateSubscriptionInstanceMutation();

  const onSubmit = useCallback(async (data: SchemaType) => {
    let cost = data.cost && data.costCurrency ? data.cost : null;
    const costCurrency =
      data.cost && data.costCurrency ? data.costCurrency : null;
    const res = await updateSubscriptionInstance({
      subscriptionInstanceId: subscriptionInstance.id,
      learnerId: data.learnerId,
      startDate: data.startDate,
      endDate: data.endDate,
      subscriptionId: data.subscriptionId,
      cost,
      costCurrency,
    });

    hide(res);
  }, []);

  const onSubmitAction = useAction(onSubmit, {
    successMessage: 'Subscription successfully updated',
    alertMessage: 'Subscription could not be updated',
  });

  const costCurriencesOptions = Object.keys(C.CURRENCIES).map((key) => ({
    value: parseInt(key),
    label: C.CURRENCIES[key],
  }));

  return (
    <SideModal.Content>
      <SideModal.Header>
        <SideModal.Title>Update Subscription</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box margin={{ bottom: 'm' }}>
          <Typography.h2>Details</Typography.h2>
        </Box>
        <Box>
          <Box>
            <FormField
              label="Learner"
              helpText="The learner for which the subscription will be updated"
              helpTextOffset={60}
              required
              errorMessage={errors?.learnerId?.message}
            >
              <Controller
                name="learnerId"
                control={control}
                render={({ field }) => (
                  <AdminLearnerSingleSelect
                    {...field}
                    initialSelectedItem={{
                      label: `${subscriptionInstance.learner?.firstName} ${subscriptionInstance.learner?.lastName}`,
                      value: subscriptionInstance.learnerId,
                    }}
                    returnEntireItemOnChange={false}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField
              required
              label="Subscription"
              helpText="The subscription you want to track for the elarner"
              helpTextOffset={30}
              errorMessage={errors?.subscriptionId?.message}
            >
              <Controller
                control={control}
                name="subscriptionId"
                render={({ field }) => (
                  <AdminSubscriptionSelect
                    {...field}
                    isClearable
                    hasError={!!errors?.subscriptionId?.message}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormField label="Start Date">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
          <Box>
            <FormField label="End Date">
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DateTime
                    date={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </FormField>
          </Box>
        </Box>
        <Box flex={{ gap: 's', flexDirection: 'row' }}>
          <FormField label="Cost">
            <Input
              type="number"
              {...register('cost', { valueAsNumber: true })}
              placeholder="Add the subscription value"
              hasError={!!errors?.cost?.message}
            />
          </FormField>
          <FormField label="Currency">
            <Controller
              name="costCurrency"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  // @ts-expect-error
                  selectedItem={field.value}
                  // @ts-expect-error
                  initialSelectedItem={field.value}
                  hasError={!!errors?.costCurrency?.message}
                  placeholder="Search currencies"
                  options={costCurriencesOptions}
                />
              )}
            />
          </FormField>
        </Box>
      </SideModal.Body>

      <SideModal.Actions>
        <Button
          label={'Update'}
          onClick={handleSubmit(onSubmitAction)}
          isLoading={isLoading}
        />
      </SideModal.Actions>
    </SideModal.Content>
  );
};
