import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ErrorPlaceholder,
  Icon,
  NoDataPlaceholder,
  NoDataPlaceholderColor,
  Pagination,
  useShowModal,
} from '@nl-lms/ui/components';
import { C } from '@nl-lms/ui/constants';
import { useRoutingAction } from '@nl-lms/ui/hooks';
import { useListViewContext, useListViewTableData } from '@nl-lms/ui/modules';

import { LearnerLearningCard } from '../../../_common/components';
import { authStore } from '../../../_common/modules/Auth/auth';
import { learnerApi } from '../../../_common/services/api';
import { routes } from '../../../lib/routes';
import {
  LearnerAppLayoutCardGrid,
  LearnerAppLayoutCardGridItem,
  LearnerAppLayoutCardGridNoData,
} from '../../_common/components/LearnerAppMainLayout/LearnerAppMainLayout';
import { LearnerLearningOpportunityCard } from '../../_common/components/LearnerLearningOpportunityCard/LearnerLearningOpportunityCard';
import { LearnerElearningPlayerSuccessModal } from '../../pages/LearnerElearningPlayerPage/LearnerElearningPlayerSuccessModal/LearnerElearningPlayerSuccessModal';
import './LearnerElearningCoursesListCards.scss';

const { useListElearningCoursesQuery } = learnerApi;

const LearnerElearningCoursesListCards = () => {
  const { query, filters } = useListViewContext();

  const fetchArg = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      query,
      categories: [
        ...C.FEATURES.learnerCatalogueElearningCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueLearningPathDropdownFilters,
      ],
    }),
    [query],
  );
  const {
    isLoading,
    data = { count: 0, rows: [] },
    isSuccess,
    isFetching,
    isError,
    // @ts-ignore
  } = useListElearningCoursesQuery(fetchArg);
  const [elearningCourses] = useListViewTableData(data);
  const { t } = useTranslation('learner');

  if (isLoading || isFetching) {
    return (
      <>
        <LearnerAppLayoutCardGrid>
          <LearnerAppLayoutCardGridItem>
            <LearnerLearningCard.Skeleton className="elearning-courses-list-cards__card" />
          </LearnerAppLayoutCardGridItem>
          <LearnerAppLayoutCardGridItem>
            <LearnerLearningCard.Skeleton className="elearning-courses-list-cards__card" />
          </LearnerAppLayoutCardGridItem>
          <LearnerAppLayoutCardGridItem>
            <LearnerLearningCard.Skeleton className="elearning-courses-list-cards__card" />
          </LearnerAppLayoutCardGridItem>
        </LearnerAppLayoutCardGrid>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <LearnerAppLayoutCardGridNoData>
          <ErrorPlaceholder>
            <ErrorPlaceholder.Image />
            {/*// @ts-ignore */}
            <ErrorPlaceholder.Title>
              {/*// @ts-ignore */}
              {t('dashboard.error.title')}
            </ErrorPlaceholder.Title>
            <ErrorPlaceholder.Description>
              {t('dashboard.error.description')}
            </ErrorPlaceholder.Description>
          </ErrorPlaceholder>
        </LearnerAppLayoutCardGridNoData>
      </>
    );
  }

  if (isSuccess && !elearningCourses.length) {
    return (
      <>
        <LearnerAppLayoutCardGridNoData>
          <NoDataPlaceholder
            iconName="MonitorIcon"
            title={
              filters
                ? t('opportunities.elearningfilterplaceholder.title')
                : t('opportunities.elearningplaceholder.title')
            }
            subtitle={
              filters
                ? t('opportunities.elearningfilterplaceholder.subtitle')
                : t('opportunities.elearningplaceholder.subtitle')
            }
            color={
              filters
                ? NoDataPlaceholderColor.warning
                : NoDataPlaceholderColor.default
            }
          />
        </LearnerAppLayoutCardGridNoData>
      </>
    );
  }

  return (
    <>
      <LearnerAppLayoutCardGrid>
        {elearningCourses.map((ec) => (
          <LearnerAppLayoutCardGridItem key={ec.id}>
            <ElearningCourseCard id={ec.id} />
          </LearnerAppLayoutCardGridItem>
        ))}
      </LearnerAppLayoutCardGrid>
    </>
  );
};

const ElearningCourseCard = ({ id }) => {
  const { query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      query,
      categories: [
        ...C.FEATURES.learnerCatalogueElearningCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueLearningPathDropdownFilters,
      ],
    }),
    [query],
  );

  // @ts-ignore
  const { course } = useListElearningCoursesQuery(fetchArg, {
    selectFromResult: ({ data }) => ({
      course: data?.rows?.find((a) => a.id === id),
    }),
  });
  const goTo = useRoutingAction();

  const { t } = useTranslation('learner');

  const showElearningPlayerSuccessModal = useShowModal(
    LearnerElearningPlayerSuccessModal,
  );

  const handleRatingClick = useCallback(() => {
    showElearningPlayerSuccessModal({
      showLogo: false,
      showSuccess: false,
      elearningCourseId: course?.id,
      surveyFormId: course?.surveyFormId,
      surveyFormInstanceId: course?.surveyFormInstanceId,
    });
  }, [
    showElearningPlayerSuccessModal,
    course?.elearningCourseId,
    course?.surveyFormId,
    course?.surveyFormInstanceId,
  ]);

  const canOfferFeedback = useMemo(() => {
    return (
      !!course?.id &&
      !!course?.surveyFormId &&
      !!course?.bestResult &&
      (course.bestResult.status === C.I_ELEARNING_SESSION_STATUSES.COMPLETED ||
        course.bestResult.status ===
          C.I_ELEARNING_SESSION_STATUSES.COMPLETED_PASSED ||
        course.bestResult.status ===
          C.I_ELEARNING_SESSION_STATUSES.COMPLETED_FAILED ||
        course.bestResult.status === C.I_ELEARNING_SESSION_STATUSES.FAILED)
    );
  }, [course]);

  if (!course || !course.id) return null;
  return (
    <LearnerLearningOpportunityCard
      name={course.name}
      description={course.description}
      type={C.I_LEARNER_LEARNING_TYPES.ELEARNING}
      // @ts-ignore
      courseType={course.contentFileVersion}
      duration={null}
      thumbnail={course.thumbnail}
      competencies={course.competencies}
      status={course.bestResult?.status}
      rating={course.rating}
      ratingCount={course.ratingCount}
      data={{ ...course, elearningCourseId: course.id }}
      actions={[
        ...(canOfferFeedback
          ? [
              <Button
                regular
                icon={<Icon.FeedbackIcon />}
                onClick={handleRatingClick}
                label={t('learningunit.feedback')}
              />,
            ]
          : []),
        <Button
          regular
          onClick={() =>
            // @ts-ignore
            goTo(routes.portal.elearningCourse.item.path.full(course.id))
          }
          label={t('common.learningactivitycard.viewmore')}
        />,
      ]}
    />
  );
};

const ElearningCoursesListCardsPagination = ({ className = '' }) => {
  const { pagination, onChangePagination, query } = useListViewContext();
  const fetchArg = useMemo(
    () => ({
      learnerId: authStore.learnerId,
      query,
      categories: [
        ...C.FEATURES.learnerCatalogueElearningCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueCourseDropdownFilters,
        ...C.FEATURES.learnerCatalogueLearningPathDropdownFilters,
      ],
    }),
    [query],
  );

  // @ts-ignore
  const { data } = useListElearningCoursesQuery(fetchArg);
  const rowCount = data?.count || 0;

  return (
    <Pagination
      className={`elearning-courses-list-cards__pagination ${className}`}
      pagination={{ ...pagination, rowCount }}
      onChangePage={onChangePagination}
    />
  );
};

LearnerElearningCoursesListCards.Pagination =
  ElearningCoursesListCardsPagination;

export default LearnerElearningCoursesListCards;
