import { isNumber } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../Box/Box';
import { StarIcon } from '../Icon';
import { Typography } from '../Typography/Typography';
import './Rating.scss';

type RatingProps = {
  total: number;
  rating: number;
  reviewCount?: number;
  iconEmpty?: ReactNode;
  iconFilled?: ReactNode;
  iconHalf?: ReactNode;
  onItemClick?: (index: number) => void;
};

export const Rating = ({
  total = 5,
  rating,
  reviewCount = 0,
  onItemClick,
  iconEmpty = <StarIcon type="outline" />,
  iconFilled = <StarIcon type="filled" />,
  iconHalf = <StarIcon type="half" />,
}: RatingProps) => {
  const totalArr = new Array(total).fill(0);
  const textStyle = { color: '#7a7f82', fontSize: '13px' };
  const [hoverIndex, setHoverIndex] = useState();

  const { t } = useTranslation('learner');

  const isRatable = useMemo(() => {
    return !!onItemClick && isNumber(hoverIndex);
  }, [onItemClick, hoverIndex]);

  const itemTypes = useMemo(() => {
    return totalArr.map((item, index) => {
      if (isRatable) {
        if ((hoverIndex || 0) < index) return iconEmpty;
        else return iconFilled;
      }

      if (index < rating && rating < index + 1) {
        if (rating < index + 0.5) return iconEmpty;
        else return iconHalf;
      } else if (index < rating) return iconFilled;
      else return iconEmpty;
    });
  }, [totalArr, rating, hoverIndex, isRatable]);

  const onMouseEnter = useCallback((index) => {
    setHoverIndex(index);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHoverIndex(undefined);
  }, []);

  const onClick = useCallback(
    (index) => {
      if (!onItemClick) return;

      onItemClick(index);
    },
    [onItemClick],
  );

  return (
    <Box
      flex={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        flex={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 's',
        }}
      >
        {rating <= total && !!rating ? (
          <Box
            className="rating__bar"
            flex={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {totalArr.map((i, index) => (
              <span
                className={`rating__item ${
                  index >= rating ? 'rating__item--empty' : ''
                } ${isRatable ? 'rating__item--clickable' : ''}`}
                onMouseEnter={() => onMouseEnter(index)}
                onMouseLeave={onMouseLeave}
                onClick={() => onClick(index)}
              >
                {itemTypes[index]}
              </span>
            ))}
          </Box>
        ) : (
          <Typography.p style={textStyle}>
            {rating > total
              ? t('common.rating.invalidData')
              : t('common.rating.noRating')}
          </Typography.p>
        )}
        <Typography.p style={textStyle}>
          {rating} / {total} ({reviewCount}{' '}
          {reviewCount > 1 || reviewCount === 0
            ? `${t('common.rating.reviews')}`
            : `${t('common.rating.review')}`}
          )
        </Typography.p>
      </Box>
    </Box>
  );
};
