import React, { useContext, useMemo } from 'react';
import { HeaderGroup, Row } from 'react-table';

import { _ } from '@nl-lms/vendor';

import { TidComponent } from '../index.types';
import {
  useReactTable,
  useTableExpandedRows,
  useTableRowActions,
  useTableRowSelection,
} from './Table.hooks';
import {
  TableAction,
  TableColumn,
  TablePagination,
  TableProps,
  TableRowAction,
  TableSelectionMode,
  TableSorting,
} from './Table.types';

type TableContextType<Entity extends Record<string, unknown>> = {
  ctxId: string;
  isLoading: boolean;
  getTableProps: () => any;
  getTableBodyProps: () => any;
  headerGroups: TidComponent<HeaderGroup<Entity>>[];
  columns: TableColumn[];
  prepareRow: any;
  ExpanderComponent: React.FunctionComponent<{ row: Entity }>;
  pagination: TablePagination;
  data: Entity[];
  onChangeSorting: (sorting?: TableSorting) => void;
  sorting: TableSorting;
  isSortable: boolean;
  isExpandable: boolean;
  isPaginated: boolean;
  tableActions: TableAction<Entity>[];
  selectedRows: Entity[];
  excludedRows: Entity[];
  allRowsAreSelected: boolean;
  rowSelection: Record<string, boolean>;
  rowExclusion: Record<string, boolean>;
  onChangePagination?: (pagination: TablePagination) => void;
  onChangeColumns?: (columns: TableColumn[]) => void;
  isHeaderEditable: boolean;
  onSelectRow: (row: Entity | Entity[] | boolean) => void;
  selectionMode: TableSelectionMode;
  rowIdAccessor: string;
  onClickRow: (row: Entity) => void;
  rowActionsCellWidth: number;
  isRowActionsVisible: boolean;
  floatingMenuActions: TableRowAction<Entity>[];
  customIconActions: TableRowAction<Entity>[];
  isSelectable: boolean;
  rows: Row<Entity>[];
  onDrop?: (from: number, to: number) => void;
  isDraggable: boolean;
  error: string;
  expandRow: (rowId: string) => void;
  expandedRows: Record<string, boolean>;
};

const createTableContext = _.once(<Entity,>() =>
  // @ts-ignore
  React.createContext({} as TableContextType<Entity>),
);
export const useTableContext = <T,>() => useContext(createTableContext<T>());
const EmptyArray = [];

export function TableProvider<Entity extends Record<string, unknown>>(
  props: TableProps<Entity>,
) {
  const {
    children,
    data,
    rowIdAccessor = 'id',
    isLoading = false,
    onChangeSorting = null,
    sorting = null,
    onClickRow = null,
    onChangePagination = null,
    onChangeColumns = null,
    tableActions = EmptyArray,
    rowActions = EmptyArray,
    selectionMode = 'checkbox',
    pagination = null,
    ExpanderComponent = null,
    onDrop = undefined,
    selectedRows: _selectedRows = undefined,
    onSelectRow: _onSelectRow = undefined,
    excludedRows: _excludedRows = undefined,
    allRowsAreSelected: _allRowsAreSelected = undefined,
    error = '',
    columns,
  } = props;
  const TableContext = createTableContext<Entity>();
  const {
    selectedRows: localSelectedRows,
    onSelectRow: localOnSelectRow,
    excludedRows: localExcludedRows,
    allRowsAreSelected: localAllRowsAreSelected,
  } = useTableRowSelection<Entity>({ data, rowIdAccessor, selectionMode });
  const selectedRows = _selectedRows || localSelectedRows;
  const excludedRows = _excludedRows || localExcludedRows;
  const allRowsAreSelected = _allRowsAreSelected || localAllRowsAreSelected;
  const onSelectRow = _onSelectRow || localOnSelectRow;
  const rowSelection = useMemo(
    () =>
      data.reduce((acc, row) => {
        if (!row) {
          console.error(`Received undefined row`);
          console.error(data);
          return acc;
        }
        const rowId = row[rowIdAccessor] as string;
        return {
          ...acc,
          [rowId]: !!selectedRows.find((r) => r[rowIdAccessor] === rowId),
        };
      }, {}),
    [selectedRows, data, rowIdAccessor],
  );
  const rowExclusion = useMemo(
    () =>
      data.reduce((acc, row) => {
        const rowId = row[rowIdAccessor] as string;
        return {
          ...acc,
          [rowId]: !!excludedRows.find((r) => r[rowIdAccessor] === rowId),
        };
      }, {}),
    [excludedRows, data, rowIdAccessor],
  );
  const { expandedRows, expandRow } = useTableExpandedRows();
  const isHeaderEditable = !!onChangeColumns;
  const {
    customIconActions,
    floatingMenuActions,
    rowActionsCellWidth,
    isRowActionsVisible,
  } = useTableRowActions(rowActions, isHeaderEditable);
  // If selection is controlled you might not use table actions
  const isSelectable = !!_onSelectRow || !!tableActions.length;
  const isSortable = !!onChangeSorting;
  const isExpandable = !!ExpanderComponent;
  const isPaginated = !!onChangePagination && !!pagination && !isLoading;
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    useReactTable<Entity>({
      columns,
      isLoading,
      data,
      pagination,
      rowIdAccessor,
      isSelectable,
      isExpandable,
      isRowActionsVisible,
    });
  const ctxId = useMemo(() => _.uniqueId('table-ctx'), []);

  return (
    <TableContext.Provider
      value={{
        ctxId,
        headerGroups,
        getTableBodyProps,
        getTableProps,
        rowIdAccessor,
        rows,
        prepareRow,
        data,
        isDraggable: !!onDrop,
        onDrop,
        selectedRows,
        onSelectRow,
        rowSelection,
        rowExclusion,
        excludedRows,
        allRowsAreSelected,
        expandedRows,
        tableActions,
        // @ts-ignore
        ExpanderComponent,
        selectionMode,
        expandRow,
        isLoading,
        isSelectable,
        isSortable,
        isExpandable,
        isPaginated,
        isRowActionsVisible,
        // @ts-ignore
        onChangeColumns,
        // @ts-ignore
        pagination,
        error,
        // @ts-ignore
        onChangePagination,
        // @ts-ignore
        sorting,
        // @ts-ignore
        onChangeSorting,
        // @ts-ignore
        onClickRow,
        customIconActions,
        floatingMenuActions,
        isHeaderEditable,
        rowActionsCellWidth,
        columns,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
