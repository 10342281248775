import React, { useMemo } from 'react';

import { transformTsRestQuery } from '@nl-lms/sdk/backend';
import {
  AsyncSingleSelect,
  SelectSkeleton,
  SingleSelectProps,
  getSelectOptions,
} from '@nl-lms/ui/components';

import { subscriptionsApi } from '../../../_common/services/api';
import { fetchAndMapSubscriptions } from '../../_common/utils/fetchEntitiesForSelectMethods';

const { useListSubscriptionsQuery } = subscriptionsApi;

const buildLabel = (item) => {
  return item.name;
};

const parseOptions = (res) => getSelectOptions(res, buildLabel);

const parseSelectedItem = (selectedItem, options) => {
  if (!selectedItem) return null;
  if (typeof selectedItem === 'string') {
    return options.find((o) => selectedItem === o.value);
  } else return selectedItem;
};

export const AdminSubscriptionSelect = (
  props: Omit<SingleSelectProps, 'options'> & { value?: string | null },
) => {
  const {
    onChange,
    name,
    placeholder = 'Search by subscription name',
    disabled = false,
    isClearable = false,
    hasError = false,
    initialSelectedItem = null,
    returnEntireItemOnChange = false,
    selectedItem = null,
    value = null,
  } = props;

  const { data, isFetching } = useListSubscriptionsQuery({
    query: { query: transformTsRestQuery({ pagination: { disabled: true } }) },
  });
  const options = useMemo(() => {
    if (!data) return [];
    return parseOptions(data.rows);
  }, [data]);

  // @ts-ignore
  const selectedValue =
    parseSelectedItem(selectedItem || value, options) || null;
  // @ts-ignore
  const initialSelectedValue =
    parseSelectedItem(initialSelectedItem, options) || null;

  const handleOnChangeInputValue = (value) => {
    if (!value) {
      onChange(null, '');
    }
  };

  if (isFetching) {
    return (
      <SelectSkeleton
        hasError={hasError}
        placeholder={'Loading subscriptions...'}
        disabled={disabled}
      />
    );
  }

  return (
    <AsyncSingleSelect
      onChange={onChange}
      onChangeInputValue={handleOnChangeInputValue}
      name={name}
      placeholder={placeholder}
      loadOptions={fetchAndMapSubscriptions}
      isClearable={isClearable}
      disabled={disabled}
      hasError={hasError}
      // @ts-ignore
      initialSelectedItem={initialSelectedValue}
      selectedItem={selectedValue}
      returnEntireItemOnChange={returnEntireItemOnChange}
    />
  );
};
