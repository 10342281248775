import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { z } from 'zod';

import { Assessment, AssessmentValidationType } from '@nl-lms/sdk/backend';
import {
  Box,
  Button,
  Checkbox,
  FormField,
  FormWizard,
  Input,
  SideModal,
  SingleSelect,
  Typography,
  useModal,
} from '@nl-lms/ui/components';
import { getTranslatedMessageFromError } from '@nl-lms/ui/utils';
import { adminApi } from '@nl-lms/web/_common/services/api';
import { AdminCertificationSelect } from '@nl-lms/web/adminApp/modules/feature-certification/AdminCertificationSelect';

import { ImageUploadInput } from '../../../../_common/modules/S3ImageUploadInput/ImageUploadInput';
import { TextEditorWithFileUpload } from '../../../../_common/modules/TextEditorWithFileUpload';
import { AdminCompetencySelect } from '../../AdminCompetency/AdminCompetencySelect';
import { AdminTagSelect } from '../../AdminTag/AdminTagSelect';
import { AdminAssessmentEditFormQuestionsSection } from './AdminAssessmentEditFormQuestionsSection';
import './AdminAssessmentEditFormSideModal.scss';

const { useCreateAssessmentMutation, useUpdateAssessmentMutation } = adminApi;

type Props = {
  assessment?: Partial<Assessment> & {
    thumbnail: string;
    certificationId: string;
  };
};

const Schema = z.object({
  name: z.string().min(1, { message: 'Assessment Name is required' }),
  description: z.string(),
  thumbnail: z.string().optional(),
  certificationId: z.string().nullable(),
  tagIds: z.array(z.string()),
  competencyIds: z.array(z.string()),
  minScore: z.coerce.number().positive('Minimum Score is required'),
  duration: z.coerce.number().positive('Duration is required'),
  maxAttemptsCount: z.coerce.number().positive('Maximum Attempts is required'),
  validationType: z.nativeEnum(AssessmentValidationType),
  showResults: z.boolean().optional(),
  randomizeQuestionOrder: z.boolean().optional(),
  allowRetakeAfterPassed: z.boolean().optional(),
  items: z
    .array(z.any())
    .min(1, { message: 'At least one question item is required' }),
});

export const AdminAssessmentEditFormSideModal = ({
  assessment = {
    name: '',
    thumbnail: '',
    description: '',
    competencyIds: [],
    tagIds: [],
    validationType: AssessmentValidationType.BestResult,
    showResults: false,
    randomizeQuestionOrder: false,
    allowRetakeAfterPassed: false,
    certificationId: '',
    items: [],
  },
}: Props) => {
  const formProps = useForm({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    defaultValues: {
      ...assessment,
      items: assessment.assessmentFormItems,
      certificationId: assessment?.certificationId || '',
    },
    shouldUnregister: false, // this will be the default in v7 - need to update when that is released
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = formProps;

  const { hide } = useModal();
  const [createAssessment, { isLoading: isCreateLoading, error: createError }] =
    useCreateAssessmentMutation();
  const [updateAssessment, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdateAssessmentMutation();
  const isLoading = isUpdateLoading || isCreateLoading;
  const error = createError || updateError;
  const mutate = assessment.id ? updateAssessment : createAssessment;
  const onSubmit = useCallback(
    async (entity) => {
      const parsedEntity = assessment.id
        ? { id: assessment.id, ...entity }
        : entity;
      const result = await mutate(parsedEntity);
      if ('data' in result) {
        hide();
      }
    },
    [mutate, hide],
  );

  const formSteps = useMemo(() => {
    if (assessment.id) return ['details', 'scoring'];
    return ['details', 'questions', 'scoring'];
  }, [assessment]);

  return (
    <SideModal.Content>
      <FormProvider {...formProps}>
        <FormWizard.Provider withContainer={false} steps={formSteps}>
          <SideModal.Header>
            <SideModal.Title>
              {assessment.id ? 'Edit Assessment' : 'Create New Assessment'}
            </SideModal.Title>
          </SideModal.Header>
          <SideModal.Body>
            <FormWizard.Step name="details">
              <AssessmentEditDetailsSection assessment={assessment} />
            </FormWizard.Step>
            <FormWizard.Step name="questions">
              <Controller
                name="items"
                control={control}
                render={({ field }) => (
                  <AdminAssessmentEditFormQuestionsSection
                    initialItems={
                      getValues('items') ||
                      assessment.items ||
                      assessment.assessmentFormItems
                    }
                    onChange={(items) => field.onChange(items)}
                    errors={errors}
                    getValues={getValues}
                  />
                )}
              />
            </FormWizard.Step>
            <FormWizard.Step name="scoring">
              <AssessmentEditScoringSection />
            </FormWizard.Step>
          </SideModal.Body>
          <SideModal.Actions>
            <SideModal.Error>
              {getTranslatedMessageFromError(error)}
            </SideModal.Error>
            <FormWizard.Navigation />
            <Button
              label={assessment.id ? 'Save' : 'Create'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </SideModal.Actions>
        </FormWizard.Provider>
      </FormProvider>
    </SideModal.Content>
  );
};

const AssessmentEditDetailsSection = ({ assessment }) => {
  const {
    register,
    getValues,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      <Box>
        <Box>
          <FormField label="Name" required errorMessage={errors?.name?.message}>
            <Input
              required
              {...register('name')}
              hasError={!!errors?.name?.message}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Description"
            required
            errorMessage={errors?.description?.message}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextEditorWithFileUpload
                  {...field}
                  initialValue={
                    assessment.description || getValues('description')
                  }
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Thumbnail"
            errorMessage={errors?.thumbnail?.message}
          >
            <Controller
              name="thumbnail"
              control={control}
              render={({ field }) => (
                <ImageUploadInput
                  {...field}
                  initialS3Url={assessment.thumbnail || getValues('thumbnail')}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Competencies">
            <Controller
              name="competencyIds"
              control={control}
              render={({ field }) => (
                <AdminCompetencySelect
                  onChange={field.onChange}
                  selectedItems={field.value}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField label="Tags">
            <Controller
              name="tagIds"
              control={control}
              render={({ field }) => (
                <AdminTagSelect
                  onChange={field.onChange}
                  selectedItems={field.value}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Certification"
            errorMessage={errors?.certificationId?.message}
          >
            <Controller
              name="certificationId"
              control={control}
              render={({ field }) => (
                <AdminCertificationSelect
                  {...field}
                  initialSelectedItem={field.value}
                  isClearable
                  hasError={!!errors?.certificationId?.message}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};

const AssessmentEditScoringSection = () => {
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext();
  const items = watch('items');
  const maxScore =
    items?.reduce(
      (acc, item) =>
        acc + (item.assessmentQuestionId ? 1 : item.questionCount || 0),
      0,
    ) || 0;

  return (
    <>
      <Box margin={{ bottom: 'm' }}>
        <Typography.h2>Scoring</Typography.h2>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Passing Score"
            errorMessage={errors?.minScore?.message}
            required
          >
            <Input {...register('minScore')} type="number" min="0" />
          </FormField>
        </Box>
        <Box>
          <FormField label="Max Score">
            <Input
              name="maxScore"
              disabled
              value={maxScore}
              type="number"
              min="0"
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Maximum Attempts"
            errorMessage={errors?.maxAttemptsCount?.message}
            required
          >
            <Input {...register('maxAttemptsCount')} type="number" min="1" />
          </FormField>
        </Box>
        <Box>
          <FormField
            label="Duration (in minutes)"
            errorMessage={errors?.duration?.message}
            required
          >
            <Input {...register('duration')} type="number" min="1" />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField
            label="Validation Type"
            required
            errorMessage={errors?.validationType?.message}
          >
            <Controller
              name="validationType"
              control={control}
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  initialSelectedItem={field.value}
                  options={[
                    {
                      value: AssessmentValidationType.BestResult,
                      label: 'Best Result ',
                    },
                    {
                      value: AssessmentValidationType.LastResult,
                      label: 'Last Result ',
                    },
                  ]}
                />
              )}
            />
          </FormField>
        </Box>
      </Box>
      <Box>
        <Box>
          <FormField errorMessage={errors?.showResults?.message}>
            <Checkbox label="Show Results" {...register('showResults')} />
          </FormField>
        </Box>
        <Box>
          <FormField errorMessage={errors?.randomizeQuestionOrder?.message}>
            <Checkbox
              label="Randomize Question Order"
              {...register('randomizeQuestionOrder')}
            />
          </FormField>
        </Box>
        <Box>
          <FormField errorMessage={errors?.allowRetakeAfterPassed?.message}>
            <Checkbox
              label="Allow Retake After Passed"
              {...register('allowRetakeAfterPassed')}
            />
          </FormField>
        </Box>
      </Box>
    </>
  );
};
