import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  AssessmentInstance,
  AssessmentInstanceAttempt,
  AssessmentQuestion,
} from '@nl-lms/sdk/backend';

import { RootState } from '../../../rootReducer';

export enum LearnerAssessmentPlayerLocation {
  Home = 'Home',
  AttemptForm = 'AttemptForm',
  AttemptResult = 'AttemptResult',
}

interface SliceAssessment {
  id: string;
  name: string;
  description: string;
  attemptsCount: number;
  attemptTimeLimit: number;
  attemptQuestions: AssessmentQuestion[];
  attemptStartDate?: string;
  attemptTimeSpent: number;
  attemptScore: number;
  attemptStatus: number;
  hasCertification: boolean;
  minScore: number;
  maxScore: number;
  maxAttempts: number;
  randomizeQuestionOrder: boolean;
  allowRetakeAfterPassed: boolean;
  showResults: boolean;
  attempt: any;
}

interface SliceState {
  playerLocation: LearnerAssessmentPlayerLocation;
  assessment: SliceAssessment;
  userAnswers: Record<string, string[]>;
  timeSpent: number;
}

const initialState: SliceState = {
  playerLocation: LearnerAssessmentPlayerLocation.Home,
  assessment: {
    id: '',
    attemptTimeLimit: 0,
    name: '',
    description: '',
    attemptQuestions: [],
    attemptsCount: 0,
    attemptScore: 0,
    attemptStatus: 1,
    hasCertification: false,
    minScore: 0,
    maxScore: 0,
    maxAttempts: 0,
    randomizeQuestionOrder: false,
    allowRetakeAfterPassed: false,
    showResults: false,
    attempt: {},
    attemptTimeSpent: 0,
  },
  userAnswers: {},
  timeSpent: 0,
};

const saveAssessmentDataAndUpdateInitialPlayerLocation = (
  state: SliceState,
  action: PayloadAction<{
    instance: AssessmentInstance;
    attempt?: AssessmentInstanceAttempt;
  }>,
) => {
  const { payload } = action;
  const { instance, attempt } = payload;
  state.assessment = {
    id: instance.id,
    // @ts-ignore
    name: instance.assessmentForm.name,
    // @ts-ignore
    description: instance.assessmentForm.description,
    // @ts-ignore
    showResults: instance.assessmentForm.showResults,
    // @ts-ignore
    attemptTimeLimit: instance.assessmentForm.duration,
    attemptQuestions: [],
    attemptsCount: instance.attemptsCount,
    attemptStartDate: attempt?.startedOn,
    // @ts-ignore
    attemptScore: instance.score,
    // @ts-ignore
    attemptStatus: instance.status,
    // @ts-ignore
    minScore: instance.assessmentForm.minScore,
    // @ts-ignore
    maxScore: instance.assessmentForm.maxScore,
    // @ts-ignore
    maxAttempts: instance.assessmentForm.maxAttemptsCount,
    // @ts-ignore
    randomizeQuestionOrder: instance.assessmentForm.randomizeQuestionOrder,
    // @ts-ignore
    allowRetakeAfterPassed: instance.assessmentForm.allowRetakeAfterPassed,
    // @ts-ignore
    hasCertification: instance.assessmentForm.hasCertification,
    attempt: attempt,
    // @ts-ignore
    attemptTimeSpent: attempt.timeSpent,
  };
  const serverAnswers = action.payload?.attempt?.answers || [];
  const parsedAnswers = {};
  if (serverAnswers.length) {
    serverAnswers.forEach((item) => {
      parsedAnswers[item.questionId] = item.answer;
    });
  }
  state.userAnswers = parsedAnswers;
  action.payload?.attempt?.answers || initialState.userAnswers;
  if (payload.attempt?.startedOn) {
    state.playerLocation = LearnerAssessmentPlayerLocation.AttemptForm;
    // @ts-ignore
    state.assessment.attemptQuestions = attempt.assessmentQuestions;
    // @ts-ignore
    state.assessment.attemptStartDate = attempt.startedOn;
  }
};

const saveAttemptDataAndGoToAssessmentFormLocation = (
  state: SliceState,
  action: PayloadAction<AssessmentInstanceAttempt>,
) => {
  // @ts-ignore
  state.assessment.attemptQuestions = action.payload.assessmentQuestions;
  state.assessment.attemptStartDate = action.payload.startedOn;
  state.assessment.attempt = action.payload;
  state.userAnswers = initialState.userAnswers;
  state.playerLocation = LearnerAssessmentPlayerLocation.AttemptForm;
};

const updateAttemptScoreAndCountAndGoToViewResultLocation = (
  state: SliceState,
  action: PayloadAction<{
    assessmentFormInstanceAttempt: any;
    assessmentFormInstance: AssessmentInstance;
  }>,
) => {
  state.assessment.attemptScore =
    action.payload.assessmentFormInstanceAttempt.score;
  state.assessment.attemptStatus =
    action.payload.assessmentFormInstanceAttempt.status;
  state.assessment.attemptsCount =
    action.payload.assessmentFormInstance.attemptsCount;
  state.assessment.attempt = action.payload.assessmentFormInstanceAttempt;
  state.playerLocation = LearnerAssessmentPlayerLocation.AttemptResult;
};

const updateUserAnswer = (
  state: SliceState,
  action: PayloadAction<{ questionId: string; answer: string[] }>,
) => {
  const { payload } = action;
  state.userAnswers[payload.questionId] = payload.answer;
};

const updateUserAnswers = (
  state: SliceState,
  action: PayloadAction<Record<string, string[]>>,
) => {
  const { payload } = action;
  state.userAnswers = payload;
};

const updateTimeSpent = (
  state: SliceState,
  action: PayloadAction<{ timeSpent: number }>,
) => {
  const { payload } = action;
  state.timeSpent = payload.timeSpent;
};

export const slice = createSlice({
  name: 'learnerAssessmentPlayer',
  initialState,
  reducers: {
    startedAssessmentAttempt: saveAttemptDataAndGoToAssessmentFormLocation,
    fetchedInitialData: saveAssessmentDataAndUpdateInitialPlayerLocation,
    endedAttempt: updateAttemptScoreAndCountAndGoToViewResultLocation,
    userUpdatedAnswer: updateUserAnswer,
    userUpdatedAnswers: updateUserAnswers,
    userUpdateTimeSpent: updateTimeSpent,
  },
});

export const actions = {
  ...slice.actions,
};

const getAssessment = (state: RootState) =>
  state.learnerAssessmentPlayer.assessment;

const getAttemptsCount = createSelector(
  getAssessment,
  (assessment: SliceAssessment) => assessment.attemptsCount,
);
const getMaxAttempts = createSelector(
  getAssessment,
  (assessment: SliceAssessment) => assessment.maxAttempts,
);
const getRemainingAttemptsCount = createSelector(
  getAttemptsCount,
  getMaxAttempts,
  (attemptsCount, maxAttempts) => maxAttempts - attemptsCount,
);
const getAttemptTimeLimit = createSelector(
  getAssessment,
  (assessment: SliceAssessment) => assessment.attemptTimeLimit,
);
const getAttemptTimeSpent = createSelector(
  getAssessment,
  (assessment: SliceAssessment) => assessment.attemptTimeSpent,
);
const getAttemptStartDate = createSelector(
  getAssessment,
  (assessment: SliceAssessment) => assessment.attemptStartDate,
);
const getQuestions = createSelector(
  getAssessment,
  (assessment: SliceAssessment) => assessment.attemptQuestions,
);
const getQuestionIds = createSelector(getQuestions, (questions) =>
  questions.map((q) => q.id),
);
const getUserAnswers = (state: RootState) =>
  state.learnerAssessmentPlayer.userAnswers;
const getIsAttemptReadyForSubmit = createSelector(
  getQuestionIds,
  getUserAnswers,
  (questionIds, userAnswers) =>
    !questionIds.some((qId) => !userAnswers[qId] || !userAnswers[qId].length),
);
const getAttemptCompletedAnswersCount = createSelector(
  getQuestionIds,
  getUserAnswers,
  (questionIds, userAnswers) =>
    questionIds.filter((qId) => userAnswers[qId] && userAnswers[qId].length)
      .length,
);

export const selectors = {
  getPlayerLocation: (state: RootState) =>
    state.learnerAssessmentPlayer.playerLocation,
  getAssessment,
  getAssessmentId: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.id,
  ),
  getQuestions,
  getIsAttemptReadyForSubmit,
  getAttemptCompletedAnswersCount,
  getUserAnswers,
  getAttemptScore: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.attemptScore,
  ),
  getMinScore: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.minScore,
  ),
  getMaxScore: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.maxScore,
  ),
  getAttemptStatus: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.attemptStatus,
  ),
  getName: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.name,
  ),
  getDescription: createSelector(
    getAssessment,
    (assessment: SliceAssessment) => assessment.description,
  ),
  getAttemptsCount,
  getMaxAttempts,
  getRemainingAttemptsCount,
  hasReachedMaxAttempts: createSelector(
    getRemainingAttemptsCount,
    (remainingAttempts) => remainingAttempts === 0,
  ),
  getAttemptTimeLimit,
  getAttemptTimeSpent,
  getAttemptStartDate,
  getRemainingAttemptTime: createSelector(
    getAttemptTimeLimit,
    getAttemptStartDate,
    (attemptTimeLimit, attemptStartDate) => {
      const attemptTimeLimitInSeconds = attemptTimeLimit * 60;
      if (!attemptStartDate) return attemptTimeLimitInSeconds;
      // @ts-ignore
      const diffInMs = new Date() - new Date(attemptStartDate);
      const remainingTime = attemptTimeLimitInSeconds - diffInMs / 1000;
      return remainingTime > 0 ? remainingTime : 0;
    },
  ),
  getUserTimeSpent: (state: RootState) =>
    state.learnerAssessmentPlayer.timeSpent,
};

export default slice.reducer;
