import * as z from 'zod';

import { SubscriptionCostType } from '@nl-lms/common/feature/types';

import { Subscription, SubscriptionInstance } from './subscription.types.sdk';

export const SubscriptionSchema: z.ZodType<Subscription> = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  vendorId: z.string(),
  tagIds: z.array(z.string()),
  baseCost: z.number().nullable(),
  costCurrency: z.number().nullable(),
  costType: z.nativeEnum(SubscriptionCostType).nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const SubscriptionInstanceSchema: z.ZodType<SubscriptionInstance> =
  z.object({
    id: z.string(),
    startDate: z.date(),
    endDate: z.date(),
    subscriptionId: z.string(),
    learnerId: z.string(),
    cost: z.number().nullable(),
    costCurrency: z.number().nullable(),
    createdAt: z.date(),
    updatedAt: z.date(),
  });

export const CreateSubscriptionSchema: z.ZodType<
  Omit<Subscription, 'id' | 'createdAt' | 'updatedAt'>
> = z
  .object({
    name: z.string(),
    url: z.string(),
    vendorId: z.string(),
    tagIds: z.array(z.string()).optional(),
    baseCost: z.number().nullable(),
    costCurrency: z.number().nullable(),
    costType: z.nativeEnum(SubscriptionCostType).nullable(),
  })
  .refine(
    (data) => {
      const allSet =
        data.baseCost !== null &&
        data.costCurrency !== null &&
        data.costType !== null;
      const allNull =
        data.baseCost === null &&
        data.costCurrency === null &&
        data.costType === null;

      return allSet || allNull;
    },
    {
      message:
        'baseCost, costCurrency, and costType must be either all set or all null',
    },
  );

export const UpdateSubscriptionSchema: z.ZodType<
  Partial<Omit<Subscription, 'id' | 'createdAt' | 'updatedAt'>>
> = z
  .object({
    name: z.string().optional(),
    url: z.string().optional(),
    vendorId: z.string().optional(),
    tagIds: z.array(z.string()).optional(),
    baseCost: z.number().nullable(),
    costCurrency: z.number().nullable(),
    costType: z.nativeEnum(SubscriptionCostType).nullable(),
  })
  .refine(
    (data) => {
      const allSet =
        data.baseCost !== null &&
        data.costCurrency !== null &&
        data.costType !== null;
      const allNull =
        data.baseCost === null &&
        data.costCurrency === null &&
        data.costType === null;

      return allSet || allNull;
    },
    {
      message:
        'baseCost, costCurrency, and costType must be either all set or all null',
    },
  );

export const CreateSubscriptionInstanceSchema: z.ZodType<
  Omit<SubscriptionInstance, 'id' | 'createdAt' | 'updatedAt'>[]
> = z
  .array(
    z.object({
      learnerId: z.string(),
      subscriptionId: z.string(),
      startDate: z.coerce.date(),
      endDate: z.coerce.date(),
      cost: z.number().nullable(),
      costCurrency: z.number().nullable(),
    }),
  )
  .refine(
    (data) => {
      const isCorrect = data.every((instance) => {
        const allSet = instance.cost !== null && instance.costCurrency !== null;
        const allNull =
          instance.cost === null && instance.costCurrency === null;

        return allSet || allNull;
      });

      return isCorrect;
    },
    {
      message: 'cost and costCurrency must be either all set or all null',
    },
  );

export const UpdateSubscriptionInstanceSchema: z.ZodType<
  Partial<Omit<SubscriptionInstance, 'id' | 'createdAt' | 'updatedAt'>>
> = z
  .object({
    learnerId: z.string().optional(),
    subscriptionId: z.string().optional(),
    startDate: z.coerce.date().optional(),
    endDate: z.coerce.date().optional(),
    cost: z.number().nullable(),
    costCurrency: z.number().nullable(),
  })
  .refine(
    (data) => {
      const allSet = data.cost !== null && data.costCurrency !== null;
      const allNull = data.cost === null && data.costCurrency === null;

      return allSet || allNull;
    },
    {
      message: 'cost and costCurrency must be either all set or all null',
    },
  );

export const SubscriptionIdParamSchema = z.object({
  subscriptionId: z.string(),
});

export const SubscriptionInstanceIdParamSchema = z.object({
  subscriptionInstanceId: z.string(),
});
